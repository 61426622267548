.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



body {

}

.container {
  background: #000;
  border-radius: 16px;
  display: grid;
  height: 100vh;
  overflow: hidden;
  place-items: center;
  position: relative;
  width: 100%;
  text-align: center;
}
.container::after {
  background-image: url("https://assets.codepen.io/9632/dolby-atmos.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px;
  content: "";
  height: 100vh;
  position: absolute;
  width: 100%;
}

.content {
  color: #fff;
  font-family: system-ui;
  font-family: "Quicksand", sans-serif;
  position: relative;
  text-align: center;
  top: -6rem;
  z-index: 2;
  color: black;
   text-align: center;
}
.content h1 {
  font-size: 10rem;
  font-weight: 300;
letter-spacing: 80px;
text-align: center;
  margin: 0;
}
.content p {
  margin: 0;
}

.box {
  --delay: 1s;
  animation: grow 10s linear infinite;
  animation-delay: var(--delay);
  background: white;
  height: 100px;
  opacity: 0.5;
  position: absolute;
  width: 100px;
  border-radius: 99px;
}
.box:nth-child(2) {
  --delay: 2s;
}
.box:nth-child(3) {
  --delay: 3s;
}
.box:nth-child(4) {
  --delay: 4s;
}
.box:nth-child(5) {
  --delay: 5s;
}
.box:nth-child(6) {
  --delay: 6s;
}
.box:nth-child(7) {
  --delay: 7s;
}
.box:nth-child(8) {
  --delay: 8s;
}
.box:nth-child(9) {
  --delay: 9s;
}
.box:nth-child(10) {
  --delay: 10s;
}

@keyframes grow {
  from {
    opacity: 0.5;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(20.85);
  }
}


.quicksand {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}